body {
  font-family: 'Century Gothic', sans-serif;
  overflow: none;
  margin: 0;
}

.leaflet-container {
  height: 100vh;
  width: 100%;
  position: relative; /* Make the container position relative */
}

.loga-container {
  background-color: #5f5f5f;
  height: 6vh;
  width: 100%;
  position: absolute;
  z-index: 1000;
  bottom: 0;
}

.loga {
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
  margin: 0 auto;
}

.filtry {
  position: absolute;
  z-index: 1000;
  bottom: 6vh;
  color: white;
  font-size: 1.1em;
  height: auto;
  width: 100%;
  pointer-events: none;
}

.filtry-div {
  background-color: rgba(30, 116, 204, 0.9);
  pointer-events: all;
}

.filtry-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
}

.filtry-text img {
  filter: invert(100%);
  margin-right: 1em;
}

.filtry-buttons-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.filtry-buttons button {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  padding: 0.5em 1em;
  margin: 0.5em;
  margin-bottom: 2em;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
}

.active {
  background-color: white !important;
  color: #1e74cc !important;
}

.buttons {
  position: absolute;
  z-index: 1000;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.btn-icon {
  margin: 10px;
  border: 3px solid #0f80d7;
  border-radius: 5px;
  width: 100px;
  height: 100px;
  cursor: pointer;
}

.btnactive {
  background-color: #0f80d7;
  border: 3px solid #0f80d7;
  border-radius: 5px;
}

.layers-container {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px 10px;
  border-radius: 5px;
  border: 3px solid #0f80d7;
  margin: 10px;
}

.layers-closebtn {
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  margin-bottom: 10px;
}

.layers-closebtn img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.invisible-marker {
  display: none;
}

.help-icon {
  position: relative;
  z-index: 1000;
  bottom: 1vh;
  left: 1vw;
  pointer-events: all;
}

.help-container {
  position: absolute;
  z-index: 1000;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.9);
  padding: 1em;
  border-radius: 5px;
  color: black;
  border: 3px solid rgba(30, 116, 204, 0.9);
  font-size: 1.1em;
  width: 45%;
  max-height: 65vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.help-closebtn {
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  margin-bottom: 10px;
  width: 100%;
}

.help-closebtn img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
/* Šířka svislého posuvníku */
::-webkit-scrollbar {
  width: 10px;
}

/* Výška vodorovného posuvníku */
::-webkit-scrollbar {
  height: 20px;
}

/* Rukojeť posuvníku */
::-webkit-scrollbar-thumb {
  background: #0f80d7;
  border-radius: 5px;
}

/* Hover efekt rukojeti posuvníku */
::-webkit-scrollbar-thumb:hover {
  background: #0f80d7d4;
}

.icon-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0.5em;
}

.icon-box2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 0.5em;
}

.icon-box p {
  width: 85%;
}

.help-container h2,
ol {
  color: #0f80d7;
  margin: 0;
}

.icon-box-icons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
}

.icon-box-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 85%;
  margin-left: 1em;
}

.icon-box-text p {
  width: 100%;
}

.icon-box-text h3 {
  margin: 0;
}

.icon-box-icons img {
  width: 100px;
  height: 100px;
  margin: 0.5em;
  cursor: pointer;
}

.import-export-container {
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px 10px;
  border-radius: 5px;
  border: 3px solid #0f80d7;
  margin: 10px;
}

.import-export-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  margin: 0 0.4em 0 0.4em;
  scale: 0.9;
}

.import-export-buttons h3 {
  margin-left: 1em;
}

.import-export-buttons label {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-size: 1.1em;
  font-weight: 600;
}



.import-export-buttons img {
  width: 40px;
  height: 40px;
}

.export-inactive {
  color: #999999 !important;
  cursor: default !important;
}

.Guide {
  width: 100vw;
  height: 94vh;
  position: absolute;
  z-index: 1005;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.85);
  white-space: pre-line;
}


.Guide h4 img {
  width: 13px;
  height: 13px;
  margin-left: 0.5em;
}

.Guide-closetext {
  cursor: pointer;
  position: absolute;
  bottom: 8vh;
  right: 2vw;
}

.Guide button {
  border: none;
  background-color: transparent;
  font-family: century gothic;
  color: #0f80d7;
  cursor: pointer;
  font-size: 1em;
}

.guide-step{
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
}

.guide-step div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1em;
}

.guide-step h4{
  margin: 0;
}

.step1 {
  bottom: 9.5vh;
  left: 5%;
}

.step2 {
  transform: translate(-50%, -50%);
  top: 85%;
  left: 50%;
  text-align: center;
}

#Guide0 {
  bottom: 8.5vh;
  left: 1vw;
  position: absolute;
}

#Guide1 {
  bottom:0;
  position: absolute;
  width: 100%;
  height: 8vh;
  pointer-events: none;
  
}

#Guide2{
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  width: 35px;
}

#Guilde3{
  border: 3px solid #0f80d7;
  background-color: white;
}

.step3 {
  top: 2%;
  left: 3%;
}

.step4 {
  top: 2%;
  right: 10%;
  text-align: right;
  width: 25%;

}

.step5 {
  top: 18%;
  right: 10%;
  text-align: right;
  width: 18%;
}

.step6 {
  top: 32%;
  right: 10%;
  text-align: right;
  width: 25%;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
}

.leaflet-popup-content-wrapper {
padding: 0 !important;
overflow: hidden;
width: 250px;
}

.leaflet-popup-content {
  margin: 0 !important;
}

.location-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0.5em;
  border-bottom: 1px solid #0f80d7;
}

.location-item button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  font-size: larger;
  cursor: pointer;
}

.savedloacationsdiv {
  max-height: 10vh;
  overflow-y: scroll;
  }

  .help-container button:last-child {
    background-color: #0f80d7;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0.5em;
    cursor: pointer;
    font-size: 1.2em;
  }
  

@media only screen and (max-width: 1500px) {
  .step1 {
    left: 7%;
    bottom: 8.5vh;
  }

  .step3 {
    top: 2%;
    left: 5%;
  }

  .step4 {
    right: 13%;
  }
  
  .step5 {
    right: 13%;
  }
  .step6 {
    right: 13%;
  }

}


@media only screen and (max-width: 1000px) {

  .Guide-closetext {
    top: 0;
    text-align: center;
    width: 100%;
  }

  #Guide1 {
    height: 11vh;
    
  }

  .step2 {
    top: 78%;
  }

  .step3 {
    top: 2%;
    left: 7%;
  }

  .step4 {
    top: 6%; 
  }

  .step5 {
    top: 10%; 
  }

  .step6 {
    top: 18%; 
  }

  .guide-step{
    width: 70%;
    font-size: 0.8em;
  }

  .step1 {
    left: 11%;
    bottom: 9.5vh;
  }

  .help-container {
    font-size: 0.9em;
  }

  .icon-box {
    flex-direction: column;
  }

  .icon-box2 {
    flex-direction: column;
  }

  .help-container img {
    width: 50%;
  }

  .icon-box-icons {
    flex-direction: row;
  }

  .help-closebtn {
    margin: 0;
  }

  .help-closebtn img {
    width: 10%;
    scale: 1.2;
  }

  .loga {
    width: 100%;
    justify-content: space-around;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
    flex-wrap: nowrap;
  }

  .loga img {
    scale: 0.7;
  }

  .filtry-buttons-btn {
    flex-direction: column;
  }

  .filtry-buttons button {
    margin-bottom: 0.5em;
  }

  .buttons button:not(.layers-closebtn) {
    scale: 0.7;
    margin: 0;
    height: 75px;
    width: 75px;
  }

  .btn-icon img {
    scale: 0.8;
  }

  .help-icon {
    scale: 0.8;
  }
}

@media only screen and (max-width: 600px) {
  .step1 {
    left: 23%;
    bottom: 9.5vh;
  }

  .step3 {
    top: 13%;
    left: 5%;
  }

  .step4 {
    top: 12%;
    left: 5%; 
    text-align: left;
  }

  .step5 {
    top: 15%;
    left: 5%; 
    text-align: left;
  }
  .step6 {
    top: 25%;
    left: 5%; 
    text-align: left;
  }

  
.savedloacationsdiv {
  max-height: none;
  overflow-y: none;
  
  }

  
.layers-container {
  max-height: 50vh;
  overflow-y: scroll;
  }

}